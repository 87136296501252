<style lang="stylus" scoped>
  @import '~assets/stylus/variable.styl'

  .right-box {
    margin-left 10px
  }
  .article-box {
    margin-top 10px;
  }
  .info-box {
    background white
    padding 10px
    border-radius border_radius
    margin-bottom 10px
  }

  @media (max-width 980px) {
    .right-grid {
      display none
    }
    .left-grid {
      width: 100%;
    }
  }
</style>

<template>
  <main class="content-container" ref="container">
    <el-row>
      <el-col :span="18" class="left-grid">
        <v-banner :data="rollingBanner" style="z-index: 1"></v-banner>
        <!--<v-article-type></v-article-type>-->
        <v-article-list v-bind:article-list="recommendList" :showThum="true" class="article-box"></v-article-list>
        <div v-if="! haveNew" class="no-more">没有更多了</div>
      </el-col>
      <el-col :span="6" class="right-grid">
        <div class="right-box">
          <v-app-qrcode></v-app-qrcode>
          <v-bast-author></v-bast-author>
          <div class="info-box">
            <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备19001380号-1</a>
          </div>
        </div>
      </el-col>
    </el-row>

  </main>
</template>

<script>
  import VBanner from '~/components/home/banner.vue';
  import VAppQrcode from '~/components/home/app-qrcode.vue';
  import VBastAuthor from '~/components/home/best-author.vue';
  import VArticleType from '~/components/home/article-type.vue';
  import VArticleList from '~/components/home/article-list.vue';
  import {scrollLoad} from '~/utils/tools';

  import { mapState} from 'vuex';

  export default {
    head: {
      title: "Uninote 首页 - 技术笔记分享社区"
    },
    components: {
      VBanner,
      VAppQrcode,
      VBastAuthor,
      VArticleType,
      VArticleList
    },

    data() {
      return {
        articleList: [],
        offset: 0,
        timeout: null,
      }
    },
    computed: {
      ...mapState('article', [
        'recommendList',
        'haveNew'
      ]),

      ...mapState('common', [
        'rollingBanner'
      ])
    },
    async asyncData({ store }) {
      if (process.server) {
        // 仅首页的 ssr 包含用户登录信息
        await store.dispatch('auth/getUserInfo');
        return store.dispatch('article/getArticleRecommend');
      }
    },
    mounted() {
      let loader = scrollLoad(this, 1000, true, () => {
        return this.$store.dispatch('article/getArticleRecommend');
      });
      if (!this.recommendList.length) {
        loader(null, true);
      }

      this.$store.dispatch('common/getRollingBanner');
    },
    beforeDestroy() {
    },
    methods: {
    }
  }
</script>
