<template>
    <div class="type-box">
      <ul class="list">
        <li v-for="type in types">
          <a v-on:click="selected(type.id)" :class="[select == type.id ? 'selected' : '']">{{ type.title }}</a>
        </li>
      </ul>
    </div>
</template>

<script>
    export default {
      name: "VArticleType",
      data () {
        return {
          select: 0,
          types: [
            {
              id: 0,
              title: '全部'
            },
            {
              id: 1,
              title: '前端'
            },
            {
              id: 2,
              title: 'Android'
            },
            {
              id: 3,
              title: '后端'
            },
            {
              id: 4,
              title: 'IOS'
            },
            {
              id: 5,
              title: '设计'
            },
            {
              id: 6,
              title: '产品'
            },
            {
              id: 7,
              title: '阅读'
            }
          ]
        }
      },
      methods: {
        selected (id) {
          this.select = id;
        }
      }
    }
</script>

<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  .type-box
    height 50px
    background white
    margin-top 10px
    border-radius 5px
    overflow hidden
  .list
    list-style-type none
  .list li
    float left
    height 50px
    line-height 50px
    font-size 14px
    padding 0 15px
    color minor_text_color
    font-weight bolder
    a
      cursor pointer
  .selected
    color base_color
</style>
