<!-- 推荐作者 -->
<template>
  <div class="recommended-authors">
    <div class="title">
      <span>推荐作者</span>
      <a id="id-change" class="page-change" @click="change">
        <i class="ion-android-refresh" style="cursor: pointer; padding: 10px 0px 10px 10px"></i>
      </a>
    </div>

    <ul id='id-authors' class="list">
      <li v-for="(author, index) in authors">
        <nuxt-link :to="{ name: 'book-uid-detail', params: {uid: author.id} }" class="avatar">
          <img v-if="author.pic" :src="author.pic">
          <img v-else src="~/assets/img/anonymous.png" alt="">
        </nuxt-link>

        <el-button @click="follow_user(index, 0)" type="primary" class="follow-btn" style="float:right" v-if="author.follow">已关注</el-button>
        <el-button @click="follow_user(index, 1)" type="primary" plain class="follow-btn" style="float:right" v-else>关注</el-button>

        <nuxt-link :to="{ name: 'book-uid-detail', params: {uid: author.id} }" class="name" :title="author.nick">{{ author.nick }}</nuxt-link>
        <p>文章：{{ author.num }}</p>
        <p>关注：{{ author.guanzhu}} 粉丝：{{ author.fensi }}</p>
      </li>
    </ul>
    <div class="find-more">
      <nuxt-link to="/author">查看全部<i class="iconfont ic-link"></i></nuxt-link>
    </div>
  </div>
</template>

<script>
  import * as apis from '~/utils/api';
  import { errorMsg, followUserWrapper } from "~/utils/tools";

  export default {
    name: "VBestAuthor",
    data() {
      return {
        authors: [],
        start: 1,
        haveData: true
      }
    },
    mounted() {
      this.initData();
    },
    methods: {
      initData() {
        apis.authorChange(this.start++).then(res => {
          this.authors = res.data;
          if (!res.show_more) {
            this.start = 1;
          }
        });
      },
      change() {
        this.initData();
      },
      /**
       * 关注用户
       */
      follow_user(param, status) {
        followUserWrapper(this.authors, param, status);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  .recommended-authors
    margin-bottom 20px
    font-size 13px
    text-align center
    background white
    border-radius border_radius
    .title
      text-align left
      padding 10px
      border-bottom 1px solid border_color_lv3
    .title span
      font-size 14px
      color base_color
      font-weight bold
    .title .page-change
      float right
      display inline-block
      font-size 16px
      color base_color
      font-weight bold
    .list
      margin 0 0 20px
      text-align left
      list-style none
    .list li
      padding: 8px 10px
      &:hover
        background-color bg_hover_color_gray
    .list .avatar
      float left
      width 48px
      height 48px
      margin-right 10px
      img
        width 100%
        height 100%
        border 1px solid border_color_lv3
        border-radius 50%
        margin-top 12px
    .list .name
      margin-right 60px
      font-size 14px
      display block
      overflow hidden
      white-space nowrap
      text-overflow ellipsis

    .list p
      margin-top 2px
      font-size 12px
      color minor_text_color
  .find-more
    padding 10px

</style>
