<template>
  <no-ssr>
    <div class="banner-wrap">
      <Slider animation="fade" v-model="siderValue" :interval="5000" :speed="500" class="slider">
        <SliderItem v-for="(item, index) in data" :key="index">
          <div class="rolling-img">
            <a :href="item.href" target="_blank">
              <img class="banner-img" :src="item.imgsrc" />
            </a>
          </div>
        </SliderItem>
      </Slider>
    </div>
  </no-ssr>
</template>

<script>
  import { Slider, SliderItem } from "vue-easy-slider";

  export default {
    name: "VBanner",
    components: {
      Slider,
      SliderItem
    },
    props: {
      data: Array
    },
    data() {
      return {
        siderValue: 0,
      }
    }
  }
</script>

<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  .banner-img {
    POSITION:relative;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    max-width:100%;
    max-height:100%
  }

  .rolling-img a {
    display: block;
  }
  .rolling-img, .rolling-img a {
    POSITION:relative;
    height: 100%;
    width: 100%;
    background: white;
  }
  /deep/ .slider-btn {
    background: none !important;
  }
  /deep/ .slider-icon-left {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      width: 1px;
      height: 12px;
      box-shadow: -5px -5px 2px bg_color;
    }
    &:after {
      content: '';
      position: absolute;
      left: 6px;
      bottom: -8px;
      width: 15px;
      height: 1px;
      box-shadow: -6px -4px 2px bg_color;
    }
  }
  /deep/ .slider-icon-right {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 1px;
      height: 15px;
      box-shadow: -5px -5px 2px bg_color;
    }
    &:after {
      content: '';
      position: absolute;
      left: 6px;
      bottom: -4px;
      width: 12px;
      height: 1px;
      box-shadow: -6px -4px 2px bg_color;
    }
  }

  .banner-wrap {
    /deep/ .slider-indicator-icon {
      margin: 0 5px;
      width: 8px;
      height: 8px;
    }
    /deep/ .slider-indicator-active {
      background: bg_color;
      box-shadow: 0 0 4px 0 #ffff;
    }

    .slider {
      border-radius: border_radius;
      height: 250px;
    }
  }
</style>
