<template>
  <div class="list-container">
    <!-- 文章列表模块 -->
    <ul id='id-articles' class="art-list">
      <li v-for="article in articleList" :class="[showThum && article.thum != '' ? 'have-img' : '']">
        <nuxt-link class="avatar-link" :to="{name: 'book-uid-detail', params: {uid: article.uid}}">
          <img :src="article.pic" height="32" width="32" v-if="article.pic" />
          <img src="~/assets/img/anonymous.png" alt="" v-else width="32" height="32">
        </nuxt-link>
        <nuxt-link :to="{name: 'book-uid-detail', params: {uid: article.uid, detail: article.aid}}">
          <div style="margin-bottom: 10px">
            <!-- 头像占位符 -->
            <span style="width: 32px; height: 32px; display: inline-block; margin-right: 10px"></span>
            <div class="author-msg">
              <b>{{ article.nick || article.uid }}</b>
              <span>在 {{ calcTime(article.updata_time) }} 前更新</span>
            </div>
          </div>

          <span class="wrap-img">
            <img class="img-blur-done" :src="article.thum" v-if="showThum">
          </span>

          <div class="content">
            <span class="title" v-html="article.title">
            </span>

            <p class="abstract" v-html="article.abstract">
            </p>

            <div class="meta">
              <span class="jsd-meta"><i class="ion-eye"></i> 阅读({{ article.uvcnt }})</span>
              <span><i class="ion-android-textsms"></i> 评论({{ article.ccnt }})</span>
              <span><i class="ion-thumbsup"></i> 点赞({{ article.likecnt }})</span>
            </div>
          </div>
        </nuxt-link>
      </li>
    </ul>
    <!-- 文章列表模块 -->
  </div>
</template>

<script>
  import { getTimeDifferenceByNow, scaleImg } from '~/utils/tools';
  import { mapState} from 'vuex';

  export default {
    name: "VArticleList",
    props: {
      articleList: Array,
      showThum: false,
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapState('common', [
        'serverTime'
      ]),
    },
    mounted() {
      this.$el.querySelectorAll("img.img-blur-done").forEach((img, i, arr) => scaleImg(img));
    },
    updated() {
      // uTODO: list 过多时，是否有性能问题？
      this.$el.querySelectorAll("img.img-blur-done").forEach((img, i, arr) => scaleImg(img));
    },
    methods: {
      calcTime(datestr) {
        return datestr ? getTimeDifferenceByNow(datestr, this.serverTime) : "";
      },
    },
  }
</script>

<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  @media (max-width 980px) {
    .wrap-img {
      display: none;
      overflow: hidden;
    }
    .art-list .have-img .content {
      padding-right: 0px !important;
    }
  }

  .list-container
    background white
    border-radius border_radius

  .art-list
    margin 0
    padding 0
    list-style none

  .art-list li
    position: relative;
    width: 100%;
    padding: 22px 10px 28px 10px;
    border-bottom: 1px solid border_color_lv3;
    word-wrap: break-word;
    &:hover
      background-color bg_hover_color_gray

  .art-list li.have-img
    min-height: 140px;

  .art-list .have-img .wrap-img
    position: absolute;
    overflow: hidden;
    top: 50%;
    margin-top: -60px;
    right: 10px;
    width: 150px;
    height: 100px;
    border-radius border_radius

  .art-list .have-img .wrap-img img
    width: 100%;
    height: 100%;
    border: 1px solid border_color_lv3;

  .art-list .have-img .content
    padding-right: 165px;

  .art-list .title
    margin: -7px 0 4px;
    display: inherit;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;

  .art-list .abstract
    margin: 0 0 8px;
    font-size: 13px;
    line-height: 24px;
    color: minor_text_color;

  .art-list .meta
    padding-right: 0 !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

  .art-list .meta span
    margin-right: 10px;
    color: minor_text_color;

  .art-list .meta a
    margin-right: 10px;
    color: minor_text_color;
  .author-msg
    display inline-block
    bottom 10px
    position relative
    font-size 14px
  .avatar-link {
    display inline-block
    position absolute
    top 22px
    img {
      border-radius 50%
    }
  }
</style>
