<template>
  <div class="qrcode-box">
      <span>
        <img src="~/assets/img/uninote-qrcode.png" alt="download app"/>
      </span>
    <span class="down-str">问题反馈、意见建议，请扫码入群交流</span>
  </div>
</template>

<script>
  export default {
    name: "VAppQrcode"
  }
</script>

<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  .qrcode-box
    background white
    padding 10px
    border-radius border_radius
    margin-bottom 10px
    span
      display inline-block
      vertical-align middle
    .down-str
      width calc(100% - 70px)
      text-align center
      font-size 13px
</style>
